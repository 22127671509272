<template>
  <v-card class="pa-2" flat>
    <v-card-title class="pb-0">
      {{ title }}
    </v-card-title>
    <prism-editor v-model="code" class="my-editor" :highlight="highlighter" line-numbers></prism-editor>
    <v-card-title v-if="withButtons" class="pt-2">
      <v-btn
        color="primary"
        outlined
        class="ml-1 mb-1"
        min-width="100px"
        min-height="40px"
        @click="$emit('closeDialog', true)"
      >
        <v-icon left dark class="mr-1">
          mdi-cancel
        </v-icon>
        {{ $lang.actions.cancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        class="mr-1 mb-1"
        min-width="100px"
        min-height="40px"
        @click="save()"
      >
        <v-icon left dark class="mr-1">
          mdi mdi-floppy
        </v-icon>
        {{ $lang.actions.save }}
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

export default {
  components: {
    PrismEditor
  },
  props: {
    title: {
      type: String,
      default: () => {
        return ''
      }
    },
    item: {
      type: String,
      default: () => {
        return ''
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    withButtons: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({ code: '' }),
  watch: {
    code: {
      handler(val) {
        if (!this.withButtons) this.$emit('fromGlobalEditor', val)
      }
    }
  },
  created() {
    if (this.item) this.code = this.item
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js) //returns html
    },
    save() {
      this.$emit('fromGlobalEditor', this.code)
      this.$emit('closeDialog', true)
    }
  }
}
</script>
<style lang="scss" scoped>

@font-face {
  font-family: 'Fira code';
  src: local('fira code'), url('~@/assets/fonts/FiraCode-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url('~@/assets/fonts/FiraMono-Regular.ttf') format('truetype');
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px;
  height: 50vh;
  overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
